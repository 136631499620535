import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Table, TableContainer, TableHeader } from './AccordionTableStyle';

const AccordionTable = ({ tableData: { field_particle, field_wasser, field_ol, field_sterile } }) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table>
                <tbody>
                    <tr>
                        <TableHeader>{t('tableFieldPneumaticType')}</TableHeader>
                        <td>{t('tableFieldParticle')}</td>
                        <td>{t('tableFieldWater')}</td>
                        <td>{t('tableFieldOil')}</td>
                        <td>{t('tableFieldSterile')}</td>
                    </tr>
                    <tr>
                        <TableHeader>{t('tableFieldProcessAir')}</TableHeader>
                        <td>{field_particle}</td>
                        <td>{field_wasser}</td>
                        <td>{field_ol}</td>
                        <td>{field_sterile ? t('tableFieldSterileFalse') : t('tableFieldSterileTrue')}</td>
                    </tr>
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default AccordionTable;
