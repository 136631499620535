import styled from 'styled-components';
import { black, greyLight, greyText } from '../../../styles/Variables';
import { GoPlus } from 'react-icons/go';

export const Title = styled.h4`
    font-size: 0.94rem;
    text-transform: uppercase;
    margin-top: 3rem;
    color: ${black};
    letter-spacing: 0.025em;
`;

export const ProductsImage = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: ${greyLight};
    height: auto;

    img {
        width: 74px;
        height: 104px;
    }
`;

export const PlusBox = styled(GoPlus)`
    width: 2rem;
    height: 2rem;

    * {
        color: ${greyText};
    }
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img,
    div {
        width: 74px;
        height: 104px;
    }
`;
