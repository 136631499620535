import React, { ReactNode, useState } from 'react';
import { Button, CloseIcon, Container, LegendContainer, LegendItem } from './AccordionLegendStyle';
import { legendContent } from '../../../utils/branchLegend';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../utils/linkHelper';

const AccordionLegend = () => {
    const [isVisible, setIsVisible] = useState(true);
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const legendCurrentContent = legendContent(language);

    const displayLegend = (): ReactNode => (
        <LegendContainer>
            {legendCurrentContent.map((item: any) => (
                <LegendItem key={item?.name}>
                    <p>{item?.name}</p>
                    <a href={linkHelper(item?.link?.url ?? '#', language)}>{item?.value}</a>
                </LegendItem>
            ))}
        </LegendContainer>
    );

    return (
        <>
            <Button isVisible={isVisible} onClick={() => setIsVisible(false)}>
                {t('legendButtonText')}
            </Button>
            <Container isVisible={!isVisible}>
                <span onClick={() => setIsVisible(true)}>
                    <CloseIcon />
                </span>
                <h3>{t('legendTitle')}</h3>
                {displayLegend()}
            </Container>
        </>
    );
};

export default AccordionLegend;
