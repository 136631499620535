import styled from 'styled-components';
import { black, darkGreen } from '../../../styles/Variables';
import { SectionTitle } from '../../../styles/Common';
import { Link } from 'gatsby';

export const SubTitle = styled.h3`
    font-size: 0.94rem;
    text-transform: uppercase;
    line-height: 20px;
    margin: 0;
    letter-spacing: 0.025rem;
    margin-top: 18px 0 11px 0;
    padding: 10px 0;
    width: 100%;
    color: ${black};
    font-family: 'Helvetica Neue LT W02_57 Cond', Arial, Helvetica;
`;

export const Title = styled(SectionTitle)`
    letter-spacing: 0.01em;
`;

export const ReferenceInformation = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
`;

export const Image = styled.img`
    max-width: 460px;
    flex-grow: 1;
`;

export const Description = styled.div`
    flex-basis: 220px;
    flex-grow: 1;
    margin-right: 1rem;
`;

export const SectionHeader = styled.p`
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 0;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
`;

export const SectionText = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    line-height: 20px;
    margin: 0 0 1rem;
`;

export const ReferenceList = styled.ul`
    list-style: none;
    margin-top: -10px;
    padding: 0;
`;

export const ReferenceLink = styled(Link)`
    font-family: Arial, Helvetica, sans-serif;
    color: ${darkGreen};
    font-size: 0.875rem;
    line-height: 20px;
`;
