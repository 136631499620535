import styled from 'styled-components';
import { black, greyLight, tableLightGray, white } from '../../../styles/Variables';

export const TableContainer = styled.div`
    overflow-x: auto;
`;

export const Table = styled.table`
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-style: hidden;
    background: ${greyLight};

    * {
        color: ${black};
        font-size: 1rem;
        font-weight: 400;
        border: 1px solid ${white};
        padding: 15px 20px;
    }
`;

export const TableHeader = styled.td`
    background: ${tableLightGray};
`;
