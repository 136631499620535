import React, { ReactNode } from 'react';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import MultiPhotoSlider from '../../Sliders/MultiPhotoSlider/MultiPhotoSlider';
import {
    ProductImage,
    ProductLinkWrapper,
    Products,
    ProductWrapper,
    ProductTitle,
    Title,
} from './AccordionProductSectionStyle';
import { urlForImage } from '../../../utils/urlForImage';
import { linkHelper } from '../../../utils/linkHelper';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TABLET_SCREEN_SIZE } from '../../../styles/MediaQuery';

const AccordionProductSection = ({ products, titleOfSection }) => {
    const currentBrowserWidth = useBrowserWidth();
    const {
        i18n: { language },
    } = useTranslation();

    const displayProducts = (): ReactNode =>
        products?.map((product: any, index: number) => (
            <ProductLinkWrapper
                href={linkHelper(product?.field_link ? product?.field_link.uri : '#', language)}
                key={product?.field_title ?? index}
            >
                <ProductWrapper>
                    <ProductTitle>{product?.field_title}</ProductTitle>
                    <ProductImage
                        src={product?.field_image?.length > 0 && urlForImage(product.field_image[0]?.field_media_image)}
                        alt={product?.alt ?? 'product image'}
                    />
                </ProductWrapper>
            </ProductLinkWrapper>
        ));

    const mobileViewChecker = (): ReactNode => {
        if (currentBrowserWidth <= TABLET_SCREEN_SIZE) {
            return <MultiPhotoSlider bigSlider useAutoWidth images={products} numberOfImagesToDisplay={1} />;
        } else {
            return <Products>{displayProducts()}</Products>;
        }
    };

    return (
        <>
            {products && <Title>{titleOfSection}</Title>}
            {mobileViewChecker()}
        </>
    );
};

export default AccordionProductSection;
