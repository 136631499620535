import React, { FC, ReactNode } from 'react';
import {
    SubTitle,
    Title,
    ReferenceInformation,
    Image,
    Description,
    SectionHeader,
    SectionText,
    ReferenceList,
    ReferenceLink,
} from './ReferenceSectionStyle';
import { ButtonWithLinkGreyGradient } from '../../../styles/Common';
import { linkHelper } from '../../../utils/linkHelper';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

interface ReferenceProductInterface {
    text: string;
    uri: string;
}

interface ReferenceSectionInterface {
    field_reference_product_links?: Array<ReferenceProductInterface>;
    field_image?: Array<{
        alt: string;
        field_media_image: string;
    }>;
    field_reference_project?: string;
    field_reference_title?: string;
    field_reference_subtitle?: string;
    field_reference_link?: ReferenceProductInterface;
    field_reference: string;
}

const ReferenceSection: FC<ReferenceSectionInterface> = ({
    field_reference_product_links,
    field_image,
    field_reference_project,
    field_reference_title,
    field_reference_subtitle,
    field_reference_link,
    field_reference,
}) => {
    const { language } = useI18next();
    const { t } = useTranslation();

    const displayProductsInUse = (): ReactNode =>
        field_reference_product_links
            .filter((referenceProduct: ReferenceProductInterface) => referenceProduct)
            .map((referenceProduct: ReferenceProductInterface) => (
                <li key={referenceProduct.uri}>
                    <ReferenceLink to={linkHelper(referenceProduct.uri, language)}>
                        {referenceProduct.text}
                    </ReferenceLink>
                </li>
            ));

    return (
        <>
            {field_reference_subtitle && <SubTitle>{field_reference_subtitle}</SubTitle>}
            {field_reference_title && <Title>{field_reference_title}</Title>}
            {field_image?.length && field_image[0]?.field_media_image && (
                <ReferenceInformation>
                    <Image src={field_image[0].field_media_image} alt={field_image[0].alt ?? 'Reference image'} />
                    <Description>
                        {field_reference && (
                            <>
                                <SectionHeader>{t('reference')}</SectionHeader>
                                <SectionText>{field_reference}</SectionText>{' '}
                            </>
                        )}
                        {field_reference_project && (
                            <>
                                <SectionHeader>{t('project')}</SectionHeader>
                            </>
                        )}
                        {field_reference_project && (
                            <>
                                <SectionText>{field_reference_project}</SectionText>
                            </>
                        )}
                        {field_reference_product_links?.length && (
                            <>
                                <SectionHeader>{t('productsInUse')}</SectionHeader>
                                <ReferenceList>{displayProductsInUse()}</ReferenceList>
                            </>
                        )}
                        {field_reference_link && (
                            <>
                                <ButtonWithLinkGreyGradient to={linkHelper(field_reference_link.uri, language)}>
                                    {field_reference_link.text}
                                </ButtonWithLinkGreyGradient>
                            </>
                        )}
                    </Description>
                </ReferenceInformation>
            )}
        </>
    );
};

export default ReferenceSection;
