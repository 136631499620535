import legendEn from '../JSON/LegendList/legend-en.json';
import legendDe from '../JSON/LegendList/legend-de.json';
import legendFr from '../JSON/LegendList/legend-fr.json';
import legendEs from '../JSON/LegendList/legend-es.json';
import legendZhHans from '../JSON/LegendList/legend-zh-hans.json';
import legendNl from '../JSON/LegendList/legend-nl.json';
import legendIt from '../JSON/LegendList/legend-it.json';
import {
    DE,
    DE_AT,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL,
    NL_BE,
    NL_NL,
    ZH_HANS,
} from '../constants/languageCode';

export const legendContent = (languageCode: string) => {
    switch (languageCode) {
        case DE:
        case DE_AT:
            return legendDe;
        case ES:
        case ES_ES:
        case ES_US:
            return legendEs;
        case FR:
        case FR_FR:
        case FR_BE:
            return legendFr;
        case IT:
            return legendIt;
        case NL:
        case NL_NL:
        case NL_BE:
            return legendNl;
        case ZH_HANS:
            return legendZhHans;
        default:
            return legendEn;
    }
};
