import styled from 'styled-components';
import {
    black,
    blue,
    containerWidth,
    darkGreen,
    greyLight,
    lightGraishButton,
    lightGray,
    lightGreyButton,
} from '../../../styles/Variables';
import { AiOutlineClose } from 'react-icons/ai';
import { deviceMax } from '../../../styles/MediaQuery';

interface LegendProps {
    isVisible: boolean;
}

export const Container = styled.div<LegendProps>`
    max-width: ${containerWidth};
    background: ${greyLight};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    padding: ${(props) => (props.isVisible ? '.5rem .5rem 1.5rem .5rem' : '0')};
    overflow: hidden;
    max-height: ${(props) => (props.isVisible ? '1000px' : '0px')};
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    transition: 0.75s;

    span {
        align-self: flex-end;
    }

    h3 {
        color: ${black};
        text-transform: uppercase;
    }
`;

export const CloseIcon = styled(AiOutlineClose)`
    font-size: 2rem;
    cursor: pointer;

    * {
        color: ${darkGreen};
    }
`;

export const Button = styled.button<LegendProps>`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    font-size: 1rem;
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    color: ${darkGreen};
    border: 1px solid ${lightGray};
    min-width: 9.4em;
    padding: 0.75em 1.875em;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2rem;
    display: block;
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    transition: 0.75s;
    font-family: Arial, Helvetica, sans-serif;
`;

export const LegendContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media ${deviceMax.tablet} {
        grid-template-columns: 1fr;
    }
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 1.5rem;
    padding: 0.2rem;

    * {
        font-size: 0.875rem;
    }

    p {
        min-width: 3.5rem;
        font-weight: 700;
        color: ${black};
    }

    a {
        color: ${blue};
        text-decoration: none;
    }
`;
