import React, { ReactNode } from 'react';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import MultiPhotoSlider from '../../Sliders/MultiPhotoSlider/MultiPhotoSlider';
import { Box, PlusBox, ProductsImage, Title } from './AccordionProductCombinationStyle';
import { urlForImage } from '../../../utils/urlForImage';
import { TABLET_SCREEN_SIZE } from '../../../styles/MediaQuery';

const AccordionProductCombination = ({ productsData }) => {
    const productsImage = productsData?.field_image;
    const firstItem = 0;
    const oneProductImage = 1;
    const currentBrowserWidth = useBrowserWidth();

    const displayImages = (): ReactNode =>
        productsImage?.map((item: any, index: number) => {
            if (index === firstItem || productsImage?.length === oneProductImage) {
                return <img key={index} src={urlForImage(item?.field_media_image)} alt="product image" />;
            } else {
                return (
                    <Box key={index}>
                        <div>
                            <PlusBox />
                        </div>
                        <img src={urlForImage(item?.field_media_image)} alt="product image" />
                    </Box>
                );
            }
        });

    const mobileViewChecker = (): ReactNode => {
        if (currentBrowserWidth <= TABLET_SCREEN_SIZE) {
            return (
                <MultiPhotoSlider
                    productCombination
                    bigSlider
                    useAutoWidth
                    images={productsImage}
                    numberOfImagesToDisplay={1}
                />
            );
        } else {
            return <ProductsImage>{displayImages()}</ProductsImage>;
        }
    };

    return (
        <>
            {productsData?.field_combination_section_captio && (
                <Title>{productsData?.field_combination_section_captio}</Title>
            )}
            {mobileViewChecker()}
        </>
    );
};

export default AccordionProductCombination;
