import React, { ReactNode, useState } from 'react';
import { graphql } from 'gatsby';
import {
    AccordionContainer,
    ButtonWrapper,
    Container,
    Description,
    PotentialApplicationsTitle,
    Title,
} from './branchStyle';
import { DownloadContainer } from '../page/pageStyle';
import { ButtonWithLinkGreyGradient } from '../../styles/Common';
import Layout from '../../components/Layout/Layout';
import Accordion from '../../components/Accordion/Accordion';
import Header from '../../components/HeaderDynamicPages/Header';
import DownloadSection from '../../components/DownloadSection/DownloadSection';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import Alternate from '../../components/Seo/Alternate';
import { dataToBreadcrumbsFromNav } from '../../utils/dataToBreadCrumbsFromNav';
import { generateStaticUrl, StaticPages } from '../../utils/generateStaticUrl';
import ReferenceSection from '../../components/Branch/ReferenceSection/ReferenceSection';

const Branch = ({
    data: {
        drupal: { branch: branch },
    },
    pageContext: { languages: languages, noIndex: noIndex },
}) => {
    const [activeAccordion, setActiveAccordion] = useState('');
    const possibleApplications = branch?.field_possible_applications;
    const { t } = useTranslation();
    const { language } = useI18next();
    const { category, additionalItem } = dataToBreadcrumbsFromNav(
        language,
        t('products').toLowerCase(),
        t('industriesBreadCrumb')
    );

    const displayPossibleApplications = (): ReactNode =>
        possibleApplications.map((item: any, index: number) => (
            <AccordionContainer key={index} onClick={() => setActiveAccordion(item?.field_title)}>
                <Accordion
                    setActiveAccordion={setActiveAccordion}
                    isActive={activeAccordion}
                    possibleApplications={item}
                />
            </AccordionContainer>
        ));

    const displayDownloadSection = (): ReactNode => (
        <DownloadContainer isTitle={true}>
            <PotentialApplicationsTitle>{t('download')}</PotentialApplicationsTitle>
            {branch?.field_attachment?.map((item: any, index: number) => (
                <DownloadSection key={index} item={item} />
            ))}
        </DownloadContainer>
    );

    return (
        <Layout
            title={branch?.metatag?.field_metatag_title}
            description={branch?.metatag?.field_metatag_description}
            languageVersionsUrl={languages}
            noIndex={noIndex || branch?.metatag?.field_metatag_no_index}
        >
            <Alternate languages={languages} />
            <Header category={category} subCategory={additionalItem} item={branch?.title} />
            <Container>
                <Title>{branch?.title}</Title>
                <Description dangerouslySetInnerHTML={{ __html: branch?.field_description }}></Description>
                <PotentialApplicationsTitle>{branch?.field_subtitle}</PotentialApplicationsTitle>
                {possibleApplications && displayPossibleApplications()}
                <ButtonWrapper>
                    <ButtonWithLinkGreyGradient to={generateStaticUrl(language, StaticPages.Contact)}>
                        {t('contactButton')}
                    </ButtonWithLinkGreyGradient>
                </ButtonWrapper>
                {branch?.field_reference && (
                    <ReferenceSection
                        field_reference_product_links={branch.field_reference_product_links}
                        field_image={branch.field_image}
                        field_reference_project={branch.field_reference_project}
                        field_reference_title={branch.field_reference_title}
                        field_reference_subtitle={branch.field_reference_subtitle}
                        field_reference_link={branch.field_reference_link}
                        field_reference={branch.field_reference}
                    />
                )}
            </Container>
            {branch?.field_attachment && displayDownloadSection()}
        </Layout>
    );
};

export default Branch;

export const query = graphql`
    query branch($id: Int!, $language: String!) {
        drupal {
            branch(id: $id, language: $language) {
                title
                field_description
                field_possible_applications {
                    field_title
                    field_combination_section_captio
                    field_headline
                    field_ol
                    field_particle
                    field_product_section_caption
                    field_small_caption
                    field_sterile
                    field_wasser
                    field_possible_products {
                        field_link {
                            text
                            uri
                        }
                        field_description
                        field_image {
                            alt
                            field_media_image
                        }
                        field_title
                    }
                    field_image {
                        field_media_image
                    }
                }
                field_reference_subtitle
                field_subtitle
                field_reference_product_links {
                    text
                    uri
                }
                field_image {
                    alt
                    field_media_image
                }
                field_reference_project
                field_reference_title
                field_subtitle
                field_reference_link {
                    text
                    uri
                }
                field_reference
                field_attachment {
                    details {
                        filemime
                        filename
                        weight
                    }
                    description
                    field_media_document
                }
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
