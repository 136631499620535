import React, { useEffect, useState } from 'react';
import {
    AccordionBox,
    AccordionButton,
    AccordionContent,
    AccordionItemHeadline,
    AccordionSmallCaption,
} from './AccordionStyle';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import AccordionTable from './AccordionTable/AccordionTable';
import AccordionProductSection from './AccordionProductSection/AccordionProductSection';
import AccordionProductCombination from './AccordionProductCombination/AccordionProductCombination';
import AccordionLegend from './AccordionLegend/AccordionLegend';

const Accordion = ({ setActiveAccordion, isActive, possibleApplications }) => {
    const [active, setActive] = useState(false);
    const activeAccordion = isActive === possibleApplications?.field_title;

    useEffect(() => {
        setActive(activeAccordion);
    }, [isActive]);

    return (
        <AccordionBox>
            <AccordionButton
                onClick={() => {
                    setActive(!active);
                    setActiveAccordion('');
                }}
            >
                {active ? <IoIosArrowDown /> : <IoIosArrowForward />}
                <span>{possibleApplications?.field_title}</span>
            </AccordionButton>
            {active && activeAccordion && (
                <AccordionContent>
                    <div>
                        <AccordionItemHeadline>{possibleApplications?.field_headline}</AccordionItemHeadline>
                        <AccordionTable tableData={possibleApplications} />
                        <AccordionSmallCaption>{possibleApplications?.field_small_caption}</AccordionSmallCaption>
                    </div>
                    <AccordionProductSection
                        products={possibleApplications?.field_possible_products}
                        titleOfSection={possibleApplications?.field_product_section_caption}
                    />
                    <AccordionProductCombination productsData={possibleApplications} />
                    <AccordionLegend />
                </AccordionContent>
            )}
        </AccordionBox>
    );
};

export default Accordion;
