import styled from 'styled-components';
import { arrowGreen, black, blue, greyBorder, greyText } from '../../styles/Variables';

export const AccordionBox = styled.div`
    border-bottom: 1px solid ${greyBorder};
    width: 100%;
    padding: 18px 0 13px 0;
`;

export const AccordionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    * {
        font-size: 2rem;
        color: ${arrowGreen};
        margin-right: 10px;
    }

    span {
        color: ${blue};
        font-size: 1.5rem;
        font-weight: 700;
        align-self: flex-end;
        line-height: 1.5rem;
        font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    }
`;

export const AccordionContent = styled.div``;

export const AccordionItemHeadline = styled.p`
    color: ${black};
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
`;

export const AccordionSmallCaption = styled.p`
    color: ${greyText};
    font-size: 0.75rem;
    margin-top: 5px;
`;
