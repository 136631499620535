import styled from 'styled-components';
import { black, containerWidth, greyLight } from '../../../styles/Variables';

export const Title = styled.h4`
    font-size: 0.94rem;
    text-transform: uppercase;
    margin-top: 4rem;
    color: ${black};
    letter-spacing: 0.025em;
`;

export const Products = styled.div`
    max-width: ${containerWidth};
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;

export const ProductLinkWrapper = styled.a`
    text-decoration: none;
`;

export const ProductWrapper = styled.div`
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    background-color: ${greyLight};
`;

export const ProductTitle = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    align-self: center;
    word-break: break-word;
`;

export const ProductImage = styled.img`
    width: 100px;
    height: 100px;
    justify-self: center;
`;
